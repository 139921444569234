exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ocad-undergrad-thesis-gallery-tsx": () => import("./../../../src/pages/ocad-undergrad-thesis/gallery.tsx" /* webpackChunkName: "component---src-pages-ocad-undergrad-thesis-gallery-tsx" */),
  "component---src-pages-ocad-undergrad-thesis-index-tsx": () => import("./../../../src/pages/ocad-undergrad-thesis/index.tsx" /* webpackChunkName: "component---src-pages-ocad-undergrad-thesis-index-tsx" */),
  "component---src-pages-ocad-undergrad-thesis-interface-tsx": () => import("./../../../src/pages/ocad-undergrad-thesis/interface.tsx" /* webpackChunkName: "component---src-pages-ocad-undergrad-thesis-interface-tsx" */),
  "component---src-pages-ocad-undergrad-thesis-orrery-tsx": () => import("./../../../src/pages/ocad-undergrad-thesis/orrery.tsx" /* webpackChunkName: "component---src-pages-ocad-undergrad-thesis-orrery-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blog-category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-tag-tsx": () => import("./../../../src/templates/blog-tag.tsx" /* webpackChunkName: "component---src-templates-blog-tag-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */)
}

